import React from 'react'
import Container from '../components/container';
import { Box, useToast } from "@chakra-ui/react";
import SEO from "../components/seo"
import Layout from "../components/layout";
import { Image, Heading, Text, Button, useColorMode } from "@chakra-ui/react"
import { Link } from "gatsby"
import { BiRocket } from 'react-icons/bi'
import { VscDebugDisconnect, VscMultipleWindows } from 'react-icons/vsc'

const Logo = require('../images/mercu_logo_full.png')
const ImgDetail = require('../images/mockup/detail.png')
const ImgProfile = require('../images/mockup/profile.png')
const ImgSchedule = require('../images/mockup/schedule.png')

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;
    prompt(): Promise<void>;
}

interface AssignmentItemSampleProps {
    accentColor: string,
    date: string,
    title: string,
    subject: string,
    left: string
}

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
}

const AssignmentItemSample: React.FC<AssignmentItemSampleProps> = ({ accentColor, date, title, subject, left }) => {
    const { colorMode } = useColorMode();

    const alterW = (): string => {
        if (typeof window !== 'undefined') {
            return `${(window.innerWidth) - 27}px`
        }

        return '400px'
    }

    return (
        <Box width={[
            alterW(),
            alterW(),
            '400px',
            '400px'
        ]} cursor='pointer' my={3} paddingY={3} paddingX={5} border="1px" borderColor={colorMode === 'dark' ? accentColor : '#E2E8F0'} borderLeft="4px" borderLeftColor={accentColor} borderRadius="5px">
            <Box display="flex" marginBottom={5}>
                <Box fontSize="sm" fontWeight="medium" width="60%">
                    {date}
                </Box>
                <Box width="40%" color={colorMode === 'dark' ? 'white' : '#BBC0C7'} textAlign="right">
                    <small>{left}</small>
                </Box>
            </Box>
            <Box color={colorMode === 'dark' ? 'white' : accentColor} fontSize="xl" fontWeight="bold">
                {subject}
            </Box>
            <Box fontSize="sm" >
                {title}
            </Box>
        </Box>
    )
}

const AboutPage = () => {
    const toast = useToast()

    React.useEffect(() => {
        const installApp = document.getElementById('installApp');

        let deferredPrompt: BeforeInstallPromptEvent;

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            deferredPrompt = e;
        });

        if (installApp) {
            installApp.addEventListener('click', async () => {
                if (deferredPrompt) {
                    deferredPrompt.prompt();
                    const { outcome } = await deferredPrompt.userChoice;
                    if (outcome === 'accepted') {
                        deferredPrompt = null;
                    }
                } else {
                    toast({
                        title: 'Terjadi kesalahan.',
                        description: 'Kamu dapat install manual dengan memilih "Add to home screen" pada ⚙️ browser kamu.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            });
        }
    }, [])

    return (
        <Layout pb={0}>
            <SEO title="Get Started" />
            <Container>
                <Box display="flex" width="100%" minH={[
                    "100vh", // base
                    "auto", // 480px upwards
                    "auto", // 768px upwards
                    "auto", // 992px upwards
                ]}>
                    <Box py="5em" marginTop="auto" marginBottom="auto">
                        <Image mb={5} width="189px" alt="Logo" src={Logo} />
                        <Heading mb={5} as="h1">
                            Cek jadwal E Learning juga POST dalam satu Genggaman.
                        </Heading>
                        <Text mb={5}>
                            Tidak perlu repot buka website kampus. Akses informasi E Learning juga POST dengan cepat melalui apliasi Mercu dengan atau tanpa koneksi internet sekalipun.
                        </Text>
                        <Button id={"installApp"} cursor="pointer" _hover={{ bg: 'primaryIndie' }} bg="primary" border="none" color="white" width="100%">Install (Android/Iphone)</Button>
                        <Link to='/'>
                            <Button cursor="pointer" mt={3} _hover={{ bg: 'primaryIndie' }} bg="primary" border="none" color="white" width="100%">Buka di Browser</Button>
                        </Link>
                    </Box>
                </Box>
            </Container>
            <Box py="5em" bg="#E2E8F0">
                <Container>
                    <Heading mb={5} as="h1" textAlign={[
                        "left", // base
                        "left", // 480px upwards
                        "left", // 768px upwards
                        "center", // 992px upwards
                    ]} textColor='secondaryIndie'>
                        Simple mudah digunakan
                    </Heading>
                </Container>
                <Box display='flex' justifyContent={[
                    "left",
                    "left",
                    "left",
                    "center",
                ]} overflow={[
                    "scroll", // base
                    "scroll", // 480px upwards
                    "scroll", // 768px upwards
                    "unset", // 992px upwards
                ]}>
                    <Image pl={3} pr={3} width="280px" src={ImgSchedule} alt="Shcedule" />
                    <Image pr={3} width="280px" src={ImgDetail} alt="Detail" />
                    <Image pr={3} width="280px" src={ImgProfile} alt="Profile" />
                </Box>
            </Box>
            <Box py='5em'>
                <Container>
                    <Heading mb={5} as="h1" textAlign={[
                        "left", // base
                        "left", // 480px upwards
                        "left", // 768px upwards
                        "center", // 992px upwards
                    ]}>
                        Lebih Intuitif dengan Kode Warna
                    </Heading>
                    <Box overflow='scroll'>
                        <Box display='inline-flex'>
                            <Box width='100px' display='flex' alignItems='center' mr={3} textAlign='right'>Menunggu dikerjakan</Box>
                            <AssignmentItemSample accentColor='primary' date='Senin, 23 October' title='Quiz Keenam' subject="Statistik &amp; Probabilitas" left='dalam 2 hari' />
                        </Box>
                    </Box>
                    <Box overflow='scroll'>
                        <Box display='inline-flex'>
                            <Box width='100px' display='flex' alignItems='center' mr={3} textAlign='right'>Sisa waktu Kurang dari 24jam</Box>
                            <AssignmentItemSample accentColor='danger' date='Senin, 23 October' title='Quiz Keenam' subject='Statistik &amp;  Probabilitas' left='dalam 2 jam' />
                        </Box>
                    </Box>
                    <Box overflow='scroll'>
                        <Box display='inline-flex'>
                            <Box width='100px' display='flex' alignItems='center' mr={3} textAlign='right'>Tugas telah dikerjakan</Box>
                            <AssignmentItemSample accentColor='success' date='Senin, 23 October' title='Quiz Keenam' subject='Statistik &amp;  Probabilitas' left='dalam 1 jam' />
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box py="5em" bg="primary" color='white'>
                <Container>
                    <Box mb={10}>
                        <Heading mb={3} as="h1" display='flex' alignItems='center'> <BiRocket size={50} /><Box ml={3}>Anti Lambat</Box></Heading>
                        <Text>Informasi disimpan pada Local Storage Device sehingga tidak perlu menunggu server kampus untuk dapat melihat jadwal.</Text>
                    </Box>
                    <Box mb={10}>
                        <Heading mb={3} as="h1" display='flex' alignItems='center'><VscDebugDisconnect size={50} /><Box ml={3}>Akses tanpa koneksi internet</Box></Heading>
                        <Text>Informasi yang telah terkumpul akan disimpan pada Local Storage device dan diperbaharui dibelakang layar agar selalu uptodate.</Text>
                    </Box>
                    <Box>
                        <Heading mb={3} as="h1" display='flex' alignItems='center'><VscMultipleWindows size={50} /><Box ml={3}>Multi Platform</Box></Heading>
                        <Text>Tersedia untuk mobile (Iphone dan Andorid) juga dekstop (Web Browser) dengan fitur yang sama.</Text>
                    </Box>
                </Container>
            </Box>
            <Box py='5em' textAlign='center' textColor='white' bg='secondary'>
                ©  2020 By Kevin Adam
                <Box>
                    <a href="mailto:adam@bykevin.work">
                        email: adam@bykevin.work
                    </a>
                </Box>
                <Box>
                    <a href="https://www.instagram.com/keevnx/">
                        Instagram
                    </a>
                </Box>
                <Box mt={3}>
                    <a href="?=none">
                        Syarat &amp; Ketentuan
                    </a>
                </Box>
                <Box >
                    <a href="?=none">
                        Kebijakan Privasi
                    </a>
                </Box>
            </Box>
        </Layout>
    )
}

export default AboutPage